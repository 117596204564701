import React, { useState } from "react";
import images from "./images.json";
import "./CuadrosNormal.css";

export default function CuadrosNormal() {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleSelected = (id) => {
    // Verifica si el id de la imagen seleccionada coincide con el id actual.
    // Si coincide, deselecciona la imagen (asigna null), de lo contrario selecciona la imagen actual.
    setSelectedImage(selectedImage === id ? null : id);
  };

  return (
    <div className="CuadrosMain">
      <span className="misCuadrosTit">Mis obras</span>
      {images.map((item) => (
        <div key={item.id}>
          <img
            onClick={() => handleSelected(item.id)}
            className={selectedImage === item.id ? "CuadrosMainMove" : "none"}
            src={require(`../img2/${item.pic}`)}
            alt={item.id}
          />
        </div>
      ))}
    </div>
  );
}
