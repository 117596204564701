import "./CLG.css";
import "./components/MisObras.js";
import MisObras from "./components/MisObras.js";
import SobreMi from "./components/SobreMi.js";
import CuadrosNormal from "./components/CuadrosNormal.js";
import Clases from "./components/Clases.js";
import Contacto from "./components/Contacto.js";
import Footer from "./components/Footer.js";
import paleta from "./img/paletaY.png";
import bike from "./img/bike.png";
import brush4 from "./img/stroke2.png";
import stroke9 from "./img/stroke9.png";
import flechaRight from "../CLG/img/flecharight.png";
import { useState } from "react";

export default function CLG() {
  const [obras, setObras] = useState(false);
  const [menu, setMenu] = useState(false);
  const [mi, setMi] = useState(false);
  const [about, setAbout] = useState(false);

  const handleObras = () => {
    setObras(!obras);
  };
  const handleMi = () => {
    setAbout(!about);
  };
  const handlePaleta = () => {
    setMenu(!menu);
  };
  const handleBike = () => {
    setMi(!mi);
  };
  return (
    <div className="mainCLG">
      <div id="first" className="firstPageCLG">
        <span className="clgTit">Carmen Lecanda</span>
        <a className="clgTit" href="#first">
          Carmen Lecanda
        </a>
        <img src={brush4} className="clgBrush" alt="brush" />
        <img src={stroke9} className="clgStroke" alt="stroke" />
        <img
          onClick={handlePaleta}
          src={paleta}
          className="clgPaleta"
          alt="paleta"
        />
        <img onClick={handleBike} src={bike} className="clgBike" alt="paleta" />
        <div className={`clgMenu ${menu ? "menuVisible" : ""}`}>
          <a onClick={handlePaleta} className="clgObra" href="#misObras">
            Mis obras
          </a>
          <div className={`formatosBox ${obras ? "clgVisibles" : ""}`}>
            <a onClick={handlePaleta} className="clgFormatos" href="#misObras">
              Formatos
            </a>
            {/* <span className="clgFormatos">Todas las obras</span> */}
            {/* <span className="clgFormatos">Formato medio</span>
            <span className="clgFormatos">Formato pequeño</span>
            <span className="clgFormatos">Retratos</span> */}
          </div>
        </div>
        <div className={`clgSobreMi ${mi ? "miVisible" : ""}`}>
          <section onClick={handleMi}>Sobre mí</section>
          <div className={`formatosBoxMi ${about ? "clgVisiblesMi" : ""}`}>
            <a href="#sobreMi" className="clgFormatosMi">
              Trayectoria
            </a>
            <a href="#exposiciones" className="clgFormatosMi">
              Exposiciones
            </a>
            <a href="#misClases" className="clgFormatosMi">
              Mis clases
            </a>
          </div>
        </div>
      </div>
      <a href="#first">
        <img className="flechaRightClg2" src={flechaRight} />
      </a>
      <a href="#sobreMi">
        <img className="flechaRightClg" src={flechaRight} />
      </a>
      {/* <a href="#first">
        <img className="flechaRightClg3" src={flechaRight} />
      </a>
      <a href="#sobreMi">
        <img className="flechaRightClg4" src={flechaRight} />
      </a> */}

      <div id="misObras">
        <MisObras />
      </div>
      <div id="cuadros">
        <CuadrosNormal />
      </div>
      <div id="sobreMi">
        <SobreMi />
      </div>
      <div id="misClases">
        <Clases />
      </div>
      <div id="contacto">
        <Contacto />
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}
