import "./Contacto.css";
import glass from "../img/glass.jpg";

export default function Contacto() {
  return (
    <div className="contactoMainCL">
      <span className="contactoTit">Contacto</span>
      <section className="formContainerGlowCL">
        <div className="formCL">
          <section className="formSectionCL">
            <span className="formTitCL">*Email</span>
            <input className="formInputCL" type="text"></input>
          </section>
          <section className="formSectionCL">
            <span className="formTitCL">*Last name</span>
            <input className="formInputCL" type="text"></input>
          </section>
          <section className="formSectionCL">
            <span className="formTitCL">*Name</span>
            <input className="formInputCL" type="text"></input>
          </section>
          <section className="formSectionCL">
            <span className="formTitCL">*Request</span>
            <input className="formInputCL" type="text"></input>
          </section>
        </div>
        <button className="contactButtonCL">Subscribe</button>
      </section>
    </div>
  );
}
